/* eslint-disable dot-notation */
import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Subscription, from, lastValueFrom, of } from 'rxjs';
import { XgsUmService } from '../xgs-service/xgs-um.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@env/environment';
import { SupersetService } from 'app/superset/superset-service/superset.service';
import { concatMap, first } from 'rxjs/operators';
import {
  OidcConfigIds,
  UtilityOptionValue,
  XGSRole
} from '@common/models/user-management.model';
import { CustomerService } from 'app/shared/services/customer-service/customer.service';
import { UmService } from '../um-service/um.service';
import { DatadogService } from 'app/shared/services/datadog-services/datadog.service';

/**
 * AuthGuard checks if user has appropriate permissions for the route
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  utilityValue: UtilityOptionValue;
  subscription: Subscription;

  private userData;
  constructor(
    private readonly router: Router,
    private readonly customerService: CustomerService,
    private readonly cookieService: CookieService,
    private readonly umService: UmService,
    private readonly xgsUmService: XgsUmService,
    private readonly supersetService: SupersetService,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly datadogService: DatadogService
  ) {
    this.subscription = this.customerService.currentCustomer.subscribe(
      utilityValue => {
        this.utilityValue = utilityValue;
      }
    );
  }

  /**
   * Checks if user has approriate permissions for the route
   * selected for the customer selected from dropdown
   * @param route - The route snapshot containing the route configuration and query parameters.
   * @returns boolean/UrlTree
   */
  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const referrer = this.cookieService.get('referrer');
    if (referrer === OidcConfigIds.GOAIGUA) {
      return true;
    }

    this.userData = this.xgsUmService.getXgsUser();
    try {
      const roles = await this.getRoles();
      if (roles.length === 0) {
        this.oidcSecurityService.authorize(this.umService.userIdp || OidcConfigIds.XGS);
      } else if (route.data.permission.includes('Superset')) {
        return await this.supersetActivate();
      } else {
        const pageAccess = roles.find(role => {
          if (role.permissions.includes(route.data['permission'])) {
            if (
              this.userData.roles[environment.xgsClientID][role.name].includes(
                this.utilityValue?.customer?.customerId
              ) ||
              this.userData.roles[environment.xgsClientID][role.name].includes(
                'AllCustomers'
              )
            ) {
              return true;
            }
          }
        });

        if (pageAccess) {
          if (route.data['access']) {
            if (this.utilityValue.access?.includes(route.data['access'])) {
              this.customerService.disableCustomersWithNoAccess(
                route.data['access']
              );

              return true;
            } else {
              const correctCustomer =
                this.customerService.getCustomerWithAccess(
                  route.data['access']
                );
              if (correctCustomer) {
                this.customerService.onUtilityChanged(correctCustomer.value);
                this.customerService.disableCustomersWithNoAccess(
                  route.data['access']
                );

                return true;
              }
            }
          } else {
            return true;
          }
        }

        return this.router.createUrlTree(['/landing']);
      }
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: `Error validating access to ${route.routeConfig.path}`
      });

      this.oidcSecurityService.authorize(this.umService.userIdp || OidcConfigIds.XGS);
    }
  }

  /**
   * Helper function to get users XGS roles
   * @returns list of user's XGS roles
   */
  async getRoles(): Promise<XGSRole[]> {
    let roles = this.xgsUmService.activeXgsUserRoles;
    if (roles.length === 0) {
      if (this.userData['sub']) {
        roles = await this.xgsUmService.getUserRolesHelper(
          this.userData['sub'],
          true
        );
      } else {
        const source$ = this.oidcSecurityService.getUserData();
        const oidcInfo = await lastValueFrom(source$);
        if (!oidcInfo) {
          return [];
        }
        this.userData = await this.xgsUmService.getUserDetailHelper(
          oidcInfo['sub']
        );
        this.userData.xgsRoles = this.userData.roles[environment.xgsClientID];
        roles = await this.xgsUmService.getUserRolesHelper(oidcInfo['sub']);
      }
    }

    return roles;
  }

  /**
   * Checks if user has valid superset token
   * @returns true/Url Tree to landing or login
   */
  async supersetActivate(): Promise<boolean | UrlTree> {
    try {
      await this.supersetService.checkSupersetAuth();

      if (
        this.cookieService.check(
          `${environment.environmentName}_superset_token`
        )
      ) {
        return true;
      }

      return this.router.createUrlTree(['/landing']);
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred accessing Superset...'
      });
      this.oidcSecurityService.authorize(
        this.umService.userIdp || OidcConfigIds.XGS
      );
    }
  }
}

/**
 * CanComponentDeactivate & UnsavedChangesGuard
 * used to monitor if user leaves page before saving inputs
 * ie creation/edits
 */
export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

/**
 * Deactivate Unsaved Changes Guard
 */
@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard {
  /**
   * Following deactivation of route checks if user has unsaved changes
   * @param component - component to deactivate
   * @returns boolean
   */
  canDeactivate(
    component: CanComponentDeactivate
  ): Observable<boolean> | Promise<boolean> | boolean {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}

/**
 * Deactivate Restricted Route Guard
 */
@Injectable({
  providedIn: 'root'
})
export class DeactivateRestrictedRoute {
  constructor(private readonly customerService: CustomerService) {}
  /**
   * Following deactivation of route enables access to all customers if route is restricted
   * @param component - component to deactivate
   * @param currentRoute - current route snapshot
   * @returns boolean
   */
  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (currentRoute.data?.access) {
      this.customerService.enableCustomersAccess();
    }

    return true;
  }
}

/**
 * SyncGuardHelper allows for asynchronous
 * Auth Guards to execute synchronously
 */
@Injectable({
  providedIn: 'root'
})
export class SyncGuardHelper {
  constructor(private readonly injector: Injector) {}

  /**
   * Executes the canActivate method of the guards in the route's data.syncGuards array
   * @param route - The route snapshot containing the route configuration and query parameters.
   * @returns An observable that emits a boolean or UrlTree value.
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return from(route.data.syncGuards).pipe(
      concatMap(value => {
        const guard = this.injector.get(value);
        const result = guard.canActivate(route);
        if (result instanceof Observable) {
          return result;
        } else if (result instanceof Promise) {
          return from(result);
        } else {
          return of(result);
        }
      }),
      first(x => {
        return x === false || x instanceof UrlTree;
      }, true)
    );
  }
}
