/* eslint-disable camelcase */
import { environment } from '@env/environment';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Component,
  HostListener,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';
import { AlertsComponent } from '@gravity-angular/base';
import {
  LoadingService,
  ResponsiveService,
  SideNavModel
} from '@gravity-angular/layout';
import {
  AuthOptions,
  EventTypes,
  OidcSecurityService,
  PublicEventsService
} from 'angular-auth-oidc-client';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { UserMenuDialogComponent } from './user-menu-dialog/user-menu-dialog.component';
import { IdleLogoutDialogComponent } from './idle-logout-dialog/idle-logout-dialog.component';
import { XgsUmService } from './auth/xgs-service/xgs-um.service';
import { CustomerService } from './shared/services/customer-service/customer.service';
import { CookieService } from 'ngx-cookie-service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { NotificationsService } from './client-common/notifications/notifications.service';
import { PreviousRouteService } from '@common/previous-route/previous-route.service';
import { SupersetService } from './superset/superset-service/superset.service';
import {
  ExdlRoles,
  OidcCommonValues,
  OidcConfigIds,
  UDLUserData,
  UtilityOption,
  UtilityOptionValue
} from '@common/models/user-management.model';
import { UmService } from './auth/um-service/um.service';
import { ColorType } from '@gravity-angular/models';
import { CustomTranslatorService } from './shared/services/custom-translator/custom-translator.service';
import { GoAiguaServiceService } from './auth/goAigua-service/go-aigua-service.service';
import { lastValueFrom } from 'rxjs';
import { DynamicRoutingService } from './shared/services/dynamic-routing/dynamic-routing.service';
import { AmplifyService } from './aws/amplify/amplify.service';
import { InvalidLoginService } from './invalid-login-dialog/invalid-login-service/invalid-login.service';
import { DatadogService } from './shared/services/datadog-services/datadog.service';

/**
 * Main component for the application
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  sideNavContent: SideNavModel;
  appName: string;
  appVersion: string;
  alerts: ComponentPortal<AlertsComponent>;
  showMainLayout: boolean;
  showSearch: boolean;
  commodityOptions;
  utilityOptions: UtilityOption[];
  keycloakUserData;
  userData: UDLUserData = {
    givenName: ' ',
    familyName: ' ',
    email: ' ',
    id: ' ',
    name: ' ',
    xgsRoles: {},
    goAiguaRoles: [],
    xgsPermissions: [],
    goAiguaPermissions: [],
    user_metadata: {
      locale: 'en_US'
    }
  };

  selectedCustomer: UtilityOptionValue;
  currentRoute: string;
  idleState = 'NOT_STARTED';
  utilityDisabled = false;
  showPsSync = false;
  validUser = false;

  constructor(
    private readonly customerService: CustomerService,
    private readonly responsiveService: ResponsiveService,
    private readonly previousRouteService: PreviousRouteService,
    private readonly invalidLoginService: InvalidLoginService,
    private readonly dynamicRoutingService: DynamicRoutingService,
    private readonly xgsUmService: XgsUmService,
    private readonly goAiguaServiceService: GoAiguaServiceService,
    private readonly loadingService: LoadingService,
    private readonly datadogService: DatadogService,
    public customTranslatorService: CustomTranslatorService,
    private readonly eventService: PublicEventsService,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly amplifyService: AmplifyService,
    public dialog: MatDialog,
    private readonly cookieService: CookieService,
    private readonly idle: Idle,
    private readonly notificationsService: NotificationsService,
    private readonly supersetService: SupersetService,
    private readonly umService: UmService,
    private readonly cd: ChangeDetectorRef
  ) {
    this.umService.setUserData(this.userData);
    this.customerService.currentCustomer.subscribe(utilityValue => {
      this.selectedCustomer = utilityValue;
      if (utilityValue?.customer?.customerId !== '') {
        this.dynamicRoutingService.setRoutes(
          this.sideNavContent,
          this.userData
        );
      }
    });

    this.customerService.utilityOptions.subscribe(options => {
      this.utilityOptions = options;
    });

    this.previousRouteService.initialize();
    this.showMainLayout = true;
    this.showSearch = false;

    this.commodityOptions = [
      {
        name: 'Data',
        value: 'DATA',
        icon: 'analytics'
      }
    ];

    // listen for the user data changed event then update user data
    // and call the signIn function in the amplify service
    this.eventService
      .registerForEvents()
      .pipe(
        filter(notification => {
          return notification.type === EventTypes.UserDataChanged;
        })
      )
      .subscribe(async result => {
        this.keycloakUserData = result.value.userData ?? {};

        this.userData.givenName = this.keycloakUserData.given_name ?? ' ';
        this.userData.familyName = this.keycloakUserData.family_name ?? ' ';
        this.userData.name = this.keycloakUserData.preferred_username;
        this.userData.email = this.keycloakUserData.email;
        this.userData.id = this.keycloakUserData.sub;
        this.userData.user_metadata = {
          locale: 'en_US'
        };

        // Activate DD RUM for session
        if (environment.dataDogAppID) {
          this.datadogService.dataDogSet(this.userData.id);
        }
        if (this.userData.givenName !== ' ') {
          cd.detectChanges();
        }

        // Check if user has roles assigned and has XGS based role assigned
        if (this.umService.userIdp === OidcConfigIds.XGS) {
          this.validUser = await this.validateXGSLogin();
          await this.xgsUmService.setXgsAuthResults();
          this.userData.user_metadata.locale =
            this.xgsUmService.xgsActiveUser?.user_metadata?.locale || 'en_US';
        } else if (this.umService.userIdp === OidcConfigIds.GOAIGUA) {
          this.validUser =
            await this.goAiguaServiceService.validateGoAiguaLogin();
          await this.goAiguaServiceService.setGoAiguaAuthResults();
          this.userData.user_metadata.locale =
            this.goAiguaServiceService.goAiguaActiveUser?.locale || 'en_US';
        }

        if (this.validUser) {
          await this.amplifyService.signIn();
          await this.umService.setUser(this.userData);
          await this.customerService.setUdlCustomersConfig();
          this.dynamicRoutingService.setRoutes(
            this.sideNavContent,
            this.userData
          );
          await this.setUser();
        }
      });

    // Listen for NewAuthenticationResult and store the new access token
    // as cookie for subdomains (i.e. superset) to use.
    this.eventService
      .registerForEvents()
      .pipe(
        filter(notification => {
          return notification.type === EventTypes.NewAuthenticationResult;
        })
      )
      .subscribe(async result => {
        if (this.umService.userIdp === OidcConfigIds.XGS) {
          await this.xgsUmService.setXgsAuthResults();
        }
        const customer = this.cookieService.get(
          `${environment.environmentName}_customer`
        );
        if (customer) {
          try {
            await this.supersetService.checkSupersetAuth();
          } catch (error) {
            this.datadogService.errorTracking(error, {
              message: 'Error occurred validating Superset auth...'
            });
          }
        }
        if (!this.appVersion) {
          this.getAppVersion();
        }
      });

    idle.setIdle(environment.idleTimeoutMinutes * 60);
    idle.setTimeout(environment.idleWarningMinutes * 60);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // When user is idle dialog will open to warn user
    idle.onIdleStart.subscribe(() => {
      this.idleState = 'IDLE';
      localStorage.setItem('idle', 'IDLE');
      const dialogRef = this.dialog.open(IdleLogoutDialogComponent, {
        width: '550px'
      });
      dialogRef.afterClosed();
    });

    // Subscribes to detect when user is no longer idle
    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'NOT_IDLE';
      localStorage.setItem('idle', 'NOT_IDLE');
      cd.detectChanges();
    });

    // Subscribes to detect when a users session is timing out
    idle.onTimeout.subscribe(() => {
      this.idleState = 'TIMED_OUT';
      localStorage.setItem('idle', 'TIMED_OUT');
      this.logout();
    });

    // Provides loading service for lazy loaded modules
    this.notificationsService.getLoadingState().subscribe(value => {
      this.loadingService.showLoading(value);
    });

    // Listens for if customer dropdown should be disabled
    this.notificationsService.getUtilitySelection().subscribe(value => {
      this.utilityDisabled = value;
      cd.detectChanges();
    });
  }

  /**
   * Checks window size to determine if responsive layout should be used
   */
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.responsiveService.checkWidth();
  }

  /**
   * Performs initial setup for the application
   */
  async ngOnInit(): Promise<void> {
    this.reset();

    this.appName = environment.appName;

    this.alerts = new ComponentPortal(AlertsComponent);
    this.sideNavContent = {
      mode: 'side',
      opened: false,
      routes: []
    };

    /**
     * Checks local storage to see if user has
     * been idle too long or logged out of a secondary tab
     */
    window.onstorage = () => {
      setTimeout(() => {
        if (!localStorage.getItem(OidcCommonValues.TAB_DETECTION)) {
          sessionStorage.clear();
          window.location.href = `${environment.xgsBaseURL}/sso/logout`;
        } else if (localStorage.getItem('idle') === 'TIMED_OUT') {
          this.logout();
        }
      }, 4000);
    };
  }

  /**
   * Call this method when we want to start/reset the idle process
   * reset any component state and be sure to call idle.watch()
   */
  reset() {
    this.idle.watch();
    this.idleState = 'NOT_IDLE';
    localStorage.setItem('idle', 'NOT_IDLE');
  }

  /**
   * Opens and closes side nav bar
   * @param opened true/false
   */
  toggleSideNav(opened?: boolean): void {
    this.sideNavContent.opened =
      opened === undefined ? !this.sideNavContent.opened : opened;
  }

  /**
   * User is logged out and redirect url updated based on token lifecycle
   */
  async logout(): Promise<void> {
    sessionStorage.removeItem('route');
    localStorage.removeItem(OidcCommonValues.TAB_DETECTION);
    this.supersetService.invalidateToken();
    await this.amplifyService.awsSignOut();

    const customParams: AuthOptions = {
      /**
       * customParams handles event of missing id_token_hint during logout
       * @param url URL to be handled
       */
      urlHandler: url => {
        const newUrl = url.replace('id_token_hint=undefined', '');
        window.location.href = newUrl;
        localStorage.removeItem(this.umService.userIdp || OidcConfigIds.XGS);
      }
    };

    this.datadogService.dataDogEnd();

    localStorage.setItem('status', 'inactive');
    await lastValueFrom(
      this.oidcSecurityService.logoff(
        this.umService.userIdp || OidcConfigIds.XGS,
        customParams
      )
    );
  }

  /**
   * Triggers onUtilityChanged function in customer service
   * @param event Selection from customer/utility dropdown
   */
  updateUtility(event: UtilityOptionValue): void {
    this.customerService.onUtilityChanged(event);
  }

  /**
   * Getter function for userData
   * @returns user data from XGS provided token
   */
  getUserData(): UDLUserData {
    return this.userData;
  }

  /**
   * Function opens dialog box along user to download ODBC driver
   */
  downloadDriver(): void {
    const dialogRef = this.dialog.open(UserMenuDialogComponent, {
      width: '640px',
      data: {
        type: 'driver'
      }
    });
    dialogRef.afterClosed();
  }

  /**
   * Function syncs PS reports
   */
  async syncPsReports(): Promise<void> {
    this.notificationsService.setLoadingState(true);
    const response = await this.supersetService.syncPsReports();
    this.notificationsService.setLoadingState(false);

    const title = response?.status === 'success' ? 'Success' : 'Error';
    const color =
      response?.status === 'success' ? ColorType.success : ColorType.danger;

    this.notificationsService.createAlert(
      color,
      title,
      response?.message,
      true
    );
  }

  /**
   * Retrieve App Version from S3
   */
  async getAppVersion(): Promise<void> {
    const s3Key = 'release_version/release_version.txt';
    const s3Data = await this.amplifyService.getFileFromS3(s3Key);
    const blob = s3Data.body;

    this.appVersion = await new Response(blob).text();
    this.datadogService.setAppVersion(this.appVersion);
  }

  /**
   * Opens help docs in new tab
   */
  openHelp(): void {
    const newWindow = window.open();
    newWindow.opener = null;
    newWindow.location.href = 'help/index.html';
  }

  /**
   * Display app version
   */
  openAboutDialog(): void {
    this.dialog.open(UserMenuDialogComponent, {
      width: '640px',
      data: {
        type: 'about',
        title: this.customTranslatorService.translate(
          $localize`:@@app_about:About`,
          'app_about'
        ),
        info: [
          `${this.customTranslatorService.translate(
            $localize`:@@app_name:Name`,
            'app_name'
          )}: ${this.appName}`,
          `${this.customTranslatorService.translate(
            $localize`:@@app_version:Version`,
            'app_version'
          )}: ${this.appVersion}`,
          `${this.customTranslatorService.translate(
            $localize`:@@app_powered:Powered by Sensus, a Xylem Brand`,
            'app_powered'
          )}`,
          `Copyright @ ${new Date().getFullYear()} Xylem, Inc`
        ]
      }
    });
  }

  /**
   * Function establishes user's access
   * from screens, roles/permissions, to customers
   */
  async setUser(): Promise<void> {
    if (
      this.umService.userIdp === OidcConfigIds.XGS &&
      this.xgsUmService.xgsAuthResults &&
      !this.selectedCustomer?.customer?.customerId
    ) {
      const tokenCustomers = this.customerService.tempCustomerList(
        this.userData
      );
      this.customerService.initUserCustomers(tokenCustomers);
      this.customerService.initUserCustomers(
        await this.xgsUmService.getXGSCustomers()
      );
    } else if (
      this.umService.userIdp === 'goaigua' &&
      !this.selectedCustomer?.customer?.customerId
    ) {
      this.customerService.initUserCustomers([
        {
          customerId: 'GOAIGUA',
          name: 'GoAigua',
          address: '',
          parentCustomerId: '',
          parentCustomerIds: [],
          childrenCustomerIds: [],
          phoneNumber: '',
          email: '',
          primaryContactUserId: '',
          xylemPrimaryContactId: '',
          status: 'ACTIVE',
          properties: {
            timezone: 'Europe/Madrid'
          }
        }
      ]);
    }

    // Check if user has permissions to sync PS reports
    this.showPsSync =
      this.umService.userIdp === OidcConfigIds.XGS &&
      this.userData.xgsPermissions.some(a => {
        return (
          a.name === ExdlRoles.SUPER_ADMIN ||
          a.name === ExdlRoles.PROFESSIONAL_SERVICES
        );
      });
  }

  /**
   * Validates the user's XGS login based on their roles.
   *
   * If the user has a role that includes 'OXI:', the function sets the user data and XGS authentication results.
   * If the user does not have such a role, the function triggers an invalid token action if the user's status is not 'logged off'.
   * @returns Returns a promise that resolves to true if the user has a valid role, otherwise does not return.
   */
  async validateXGSLogin(): Promise<boolean> {
    if (
      (!this.keycloakUserData.roles ||
        !Object.keys(this.keycloakUserData.roles).some(role => {
          return role.includes('OXI:');
        })) &&
      localStorage.getItem('status') !== 'inactive'
    ) {
      await this.invalidLoginService.tokenInvalid();

      return false;
    } else {
      await this.xgsUmService.setXgsAuthResults();
      await this.xgsUmService.setUser(this.userData);

      return true;
    }
  }
}
