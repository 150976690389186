import { LogLevel } from 'angular-auth-oidc-client';
import { FeatureFlags } from '../app/shared/services/feature-flags/feature-flags.service';

/* eslint-disable @typescript-eslint/naming-convention */
export const environment = {
  appVersion: require('../../package.json').version + '-poc',
  production: false,
  environmentName: 'poc',
  localhostRedirect: false,
  systemInfoUrl: '',
  appName: 'Xylem Data Lake',
  apiBaseUrl: '/gravity-angular-ui/svc/',
  request: {
    headers: {
      'Content-Type': 'application/json',
      'APP-ID': 'gravity-angular-ui'
    },
    withCredentials: true
  },
  supersetUrl: 'https://superset.poc-us-east-1-451784412026.oxi-devs.com/sync',
  xgsClientID: 'c5cb9883-2cb1-4a0c-8de4-41a8eac257cf', // qa cluster
  goAiguaClientID: 'xylem-data-lake',
  odbcBucket: '451784412026-us-east-1-odbc-redshift-driver',
  unloadBucket: '451784412026-us-east-1-redshift-unload',
  dataImportBucket: '451784412026-<<REGION>>-customer-data-imports',
  domain: 'poc-us-east-1-451784412026.oxi-devs.com',
  healthMonitorAPI:
    'https://0zpvpnz931.execute-api.us-east-1.amazonaws.com/healtheventpoc',
  customerOnboardAPI:
    'https://api.poc-us-east-1-451784412026.oxi-devs.com/customer-onboard',
  XGSbiTOOLRolesAPI:
    'https://api.poc-us-east-1-451784412026.oxi-devs.com/API_XGS_biTOOL_Roles',
  GUIUdlUsageAPI:
    'https://api.poc-us-east-1-451784412026.oxi-devs.com/GUI_UDL_Usage',
  BRE_API: 'https://api.poc-us-east-1-451784412026.oxi-devs.com/API_UDL_BRE',
  UM_Audit_API:
    'https://api.poc-us-east-1-451784412026.oxi-devs.com/API_UDL_UM_AUDIT_TRAIL',
  s2sMgmtAPI:
    'https://api.poc-us-east-1-451784412026.oxi-devs.com/API_UDL_S2S_MGMT',
  userTracking:
    'https://api.poc-us-east-1-451784412026.oxi-devs.com/user-tracking',
  udlSchedulerApi:
    'https://api.poc-us-east-1-451784412026.oxi-devs.com/udl-scheduler',
  supersetApi: 'https://api.poc-us-east-1-451784412026.oxi-devs.com/superset',
  dataImportApi:
    'https://api.poc-us-east-1-451784412026.oxi-devs.com/exdl-data-import',
  xgsIdp: 'cloudfront-qa.xylem-cloud.com/xgs/auth/realms/xgsum',
  goAiguaIdp: 'auth-eu.go-aigua.com/auth/realms/xpre',
  goAiguaRedirect: 'portal-xpre.xylemvue.goaigua.com',
  xgsBaseURL: 'https://cloudfront-qa.xylem-cloud.com/xgs',
  cognitoIdp: 'us-east-1:fef4db95-2d0b-4298-a292-3d125ea1094c',
  logrocket: 'whide7/utility-data-lake',
  clusteringAnalysisAPI:
    'https://api.poc-us-east-1-451784412026.oxi-devs.com/ClusteringAnalysis/',
  logLevel: LogLevel.Debug,
  maxS2SLogins: 5,
  idleTimeoutMinutes: 28,
  idleWarningMinutes: 2,
  dataUsageLimit: 536870912000,
  dataUsageWarning: 402653184000,
  xgsUsersPageSize: 275,
  dataDogAppID: null,
  dataDogClientToken: null,
  dataDogEnv: 'poc',
  localeExpirationSeconds: 1800,
  featureFlags: {
    DATA_IMPORT: true,
    HEALTH_MONITOR: true,
    CLUSTER_ANALYSIS: true,
    APP_STORE: true,
    BUSINESS_RULE_ENGINE: true
  } as FeatureFlags
};
