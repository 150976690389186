import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { InvalidLoginService } from 'app/invalid-login-dialog/invalid-login-service/invalid-login.service';
import { GoAiguaUser } from '@common/models/go-aigua.model';
import { lastValueFrom } from 'rxjs';
import { DatadogService } from 'app/shared/services/datadog-services/datadog.service';
import { OidcConfigIds } from '@common/models/user-management.model';

export enum GoAiguaLocals {
  EN = 'en_US',
  ES = 'es_ES'
}

/**
 * Service for GoAigua POC authentication operations.
 */
@Injectable({
  providedIn: 'root'
})
export class GoAiguaServiceService {
  goAiguaActiveUser: GoAiguaUser;
  goAiguaAuthResults = {} as GoAiguaAuthResults;

  constructor(
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly invalidLoginService: InvalidLoginService,
    private readonly datadogService: DatadogService
  ) {
    this.setGoAiguaAuthResults();
  }

  /**
   * Retrieves and sets the 'goaigua' authentication results.
   */
  async setGoAiguaAuthResults(): Promise<void> {
    const source$ = this.oidcSecurityService.getAuthenticationResult(
      OidcConfigIds.GOAIGUA
    );
    this.goAiguaAuthResults = await lastValueFrom(source$);
  }

  /**
   * Sets the active GoAigua user.
   *
   * Retrieves the user payload from the 'goaigua' access token and sets the locale.
   * @returns A promise that resolves when the operation is complete.
   */
  async setGoAiguaActiveUser(): Promise<void> {
    const source$ = this.oidcSecurityService.getPayloadFromAccessToken(
      false,
      OidcConfigIds.GOAIGUA
    );
    this.goAiguaActiveUser = (await lastValueFrom(source$)) as GoAiguaUser;
    this.goAiguaActiveUser.locale =
      GoAiguaLocals[this.goAiguaActiveUser.locale.toUpperCase()] ||
      GoAiguaLocals.EN;
  }

  /**
   * Validates the GoAigua login based on user roles.
   *
   * Checks if the user has either 'UDL_READ_ONLY' or 'UDL_SUPER_ADMIN' roles. If not, triggers an invalid token action.
   * @returns A promise that resolves to true if the user has a valid role, false if an error occurs.
   */
  async validateGoAiguaLogin(): Promise<boolean> {
    try {
      await this.setGoAiguaActiveUser();
      if (
        !this.goAiguaActiveUser.resource_access ||
        !this.goAiguaActiveUser.resource_access[environment.goAiguaClientID] ||
        !this.goAiguaActiveUser.resource_access[environment.goAiguaClientID]
          .roles ||
        !this.goAiguaActiveUser.resource_access[
          environment.goAiguaClientID
        ].roles.some((role: string) => {
          return role === 'UDL_READ_ONLY' || role === 'UDL_SUPER_ADMIN';
        })
      ) {
        this.invalidLoginService.tokenInvalid();
      } else {
        return true;
      }
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error validating GoAigua login'
      });
      return false;
    }
  }
}

export interface GoAiguaAuthResults {
  access_token: string;
  refresh_token: string;
  id_token: string;
  expires_in: number;
  refresh_expires_in: number;
  scope: string;
  session_state: string;
  state: string;
  token_type: string;
}
